import React from 'react'
import Layout from '../layouts/index.js'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import styles from './blog.module.css'

export default class Blog extends React.Component {
  getCoverImageUrl = (post) => {
    try {
      const url = post.frontmatter.thumbnail.childImageSharp.resize.src
      return url
    } catch (e) {
      return ''
    }
  }

  renderArticleSummary = (post) => {
    return (
      <div className={styles.post} key={post.frontmatter.slug}>
        <div className={styles.cover}>
          <img alt="Cover" src={this.getCoverImageUrl(post)} />
        </div>
        <div className={styles.body}>
          <h4>
            <Link to={post.frontmatter.slug}>{post.frontmatter.title}</Link>
          </h4>
          <div className={styles.date}>
            Added <span>{post.frontmatter.date}</span>
          </div>
          <div className={styles.description}>
            <p>{post.frontmatter.description}</p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const posts = this.props.data.blogPosts
      ? this.props.data.blogPosts.edges
      : []

    return (
      <Layout>
        <Helmet
          title={'Our product blog | Suggested - Build better products'}
        ></Helmet>
        <div className="page">
          <h1 className="text-center">Our blog</h1>
          <h5 className="text-center">
            Product development, startups, and new Suggested features
          </h5>

          <div className={styles.blogList}>
            {posts.map((item) => this.renderArticleSummary(item.node))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogPostsQuery {
    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { type: { eq: "blogPost" }, draft: { eq: false } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
            draft
            description
            date(formatString: "MMMM DD, YYYY")
            thumbnail {
              childImageSharp {
                resize(width: 250, height: 200, quality: 95) {
                  src
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
